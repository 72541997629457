import React from "react"
import Heading from "../components/content/heading"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Rows from "../components/grid/rows"
import Columns from "../components/grid/columns"
import { Content } from "../components/grid"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import Copy from "../components/content/copy"
import SectionBackground from "@src/components/containers/section-background"

function PrivacyPage({ location }) {
  const seoObj = {
    title: "Lunio's Data Processing Agreement",
    description:
      "If you have any questions or queries about us, our Site, our Services, our Policy or these Terms, please contact us at by email at support@lunio.ai.",
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent hero>
            <Rows gap="none" className="mb-md">
              <Columns count="1">
                <Content gap="md">
                  <Heading className="mt-xl" level={1}>
                    Data Processing Agreement (DPA)
                  </Heading>
                  <Copy>
                    <p>(Version: February 2022)</p>
                  </Copy>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>
      <Section>
        <SectionContent paddingTop="none">
          <Rows gap="xxl">
            <Columns count="1">
              <Content>
                <p>
                  <strong>
                    Please read this DPA carefully before proceeding.
                  </strong>
                </p>

                <p>
                  This DPA is entered into on the date it is signed between you
                  and PPC Protect Limited, with company number 10359736 and
                  registered office at 4th Floor Hyphen Building, 75 Mosley St,
                  Manchester, United Kingdom, M2 3HR (
                  <strong>we, us, PPC</strong> and <strong>our</strong>
                  ).
                </p>

                <Heading className="mt-xl" level={3}>
                  1. DEFINITIONS
                </Heading>

                <p>
                  1. Words that are capitalised but have not been defined in
                  this DPA have the meanings given to them in the Conditions. In
                  addition, in this DPA the following definitions have the
                  meanings given below:
                </p>

                <p>
                  Applicable Law means the following as applicable and binding
                  on either party or the PPC Package:
                </p>

                <p>
                  (a) any law, statute, regulation, byelaw or subordinate
                  legislation in force from time to time;
                </p>

                <p>
                  (b) the common law and laws of equity as applicable to the
                  parties from time to time;
                </p>

                <p>(c) any binding court order, judgment or decree; or</p>

                <p>
                  (d) any applicable direction, policy, rule or order made or
                  given by any regulatory body having jurisdiction over a party
                  or any of that party’s assets, resources or business.
                </p>

                <p>
                  <strong>Conditions</strong> means our terms and conditions of
                  supply entered into between the parties governing the
                  provision and use of the Services.
                </p>

                <p>
                  <strong>Controller</strong> has the meaning given to that term
                  in Data Protection Laws.
                </p>

                <p>
                  <strong>Data Protection Losses</strong> means all liabilities,
                  including all:
                </p>

                <p>
                  (e) costs (including legal costs), claims, demands, actions,
                  settlements, interest, charges, procedures, expenses, losses
                  and damages (including relating to material or non-material
                  damage); and
                </p>

                <p>(f) to the extent permitted by Applicable Law:</p>

                <p>
                  (i) administrative fines, penalties, sanctions, liabilities or
                  other remedies imposed by a Supervisory Authority;
                </p>

                <p>
                  (ii) compensation which is ordered by a court or Supervisory
                  Authority to be paid to a Data Subject; and
                </p>

                <p>
                  (iii) the reasonable costs of compliance with investigations
                  by a Supervisory Authority.
                </p>

                <p>
                  <strong>Data Subject</strong> has the meaning given to that
                  term in Data Protection Laws.
                </p>
                <p>
                  <strong>Data Subject Request</strong> means a request made by
                  a Data Subject to exercise any rights of Data Subjects under
                  Chapter III of the GDPR.
                </p>
                <p>
                  <strong>Good Industry Practice</strong> means the exercise of
                  that degree of care, diligence and skill which would
                  reasonably and ordinarily be expected from a skilled,
                  professional and experienced person engaged in the same type
                  of undertaking under the same or similar circumstances.
                </p>
                <p>
                  <strong>International Recipient</strong> means the
                  organisations, bodies, persons and other recipients to which
                  Transfers of the Protected Data are prohibited under paragraph
                  ‎7.1 without your prior written authorisation.
                </p>
                <p>
                  <strong>Lawful Safeguards</strong> means such legally
                  enforceable mechanism(s) for Transfers of Personal Data as may
                  be permitted under Data Protection Laws from time to time.
                </p>
                <p>
                  <strong>Personal Data Breach</strong> means any breach of
                  security leading to the accidental or unlawful destruction,
                  loss, alteration, unauthorised disclosure of, or access to,
                  any Protected Data.
                </p>
                <p>
                  <strong>processing</strong> has the meaning given to that term
                  in Data Protection Laws (and related terms such as{" "}
                  <strong>process</strong>, <strong>processes</strong> and{" "}
                  <strong>processed</strong> have corresponding meanings).
                </p>
                <p>
                  <strong>Processing Instructions</strong> has the meaning given
                  to that term in paragraph ‎3.1.1.
                </p>
                <p>
                  <strong>Processor</strong> has the meaning given to that term
                  in Data Protection Laws.
                </p>

                <p>
                  <strong>Sub-Processor</strong> means a Processor engaged by us
                  or by any other Sub-Processor for carrying out processing
                  activities in respect of the Protected Data on your behalf.
                </p>

                <p>
                  <strong>Supervisory Authority</strong> means any local,
                  national or multinational agency, department, official,
                  parliament, public or statutory person or any government or
                  professional body, regulatory or supervisory authority, board
                  or other body responsible for administering Data Protection
                  Laws.
                </p>

                <p>
                  <strong>Transfer</strong> bears the same meaning as the word
                  ‘transfer’ in Article 44 of the GDPR (and related terms such
                  as <strong>Transfers</strong>, <strong>Transferred</strong>{" "}
                  and <strong>Transferring</strong> have corresponding
                  meanings).
                </p>

                <Heading className="mt-xl" level={3}>
                  2. PROCESSOR AND CONTROLLER
                </Heading>

                <p>
                  2.1. For the Protected Data, if you are the Controller and we
                  are the Processor the terms of this DPA will apply. Nothing in
                  our Agreement relieves you of any responsibilities or
                  liabilities under any Data Protection Laws.
                </p>

                <p>
                  2.2. To the extent you are not sole Controller of any
                  Protected Data you warrant that you have full authority and
                  authorisation of all relevant Controllers to instruct us to
                  process the Protected Data in accordance with our Agreement.
                </p>

                <p>2.3. We will process Protected Data in compliance with:</p>
                <p>
                  2.3.1 the obligations of Processors under Data Protection Laws
                  in respect of the performance of our obligations under our
                  Agreement; and
                </p>

                <p>2.3.2. the terms of our Agreement.</p>

                <p>
                  2.4. You will ensure that each Authorised User will at all
                  times comply with:
                </p>

                <p>
                  2.4.1 all Data Protection Laws in connection with the
                  processing of Protected Data, the use of the PPC Package (and
                  each part) and the exercise and performance of your respective
                  rights and obligations under our Agreement, including
                  maintaining all relevant regulatory registrations and
                  notifications as required under Data Protection Laws; and
                </p>

                <p>2.4.2 the terms of our Agreement.</p>

                <p>
                  2.5. You warrant, represent and undertake, that at all times:
                </p>

                <p>
                  2.5.1. the processing of all Protected Data (if processed in
                  accordance with our Agreement) will comply in all respects
                  with Data Protection Laws, including in terms of its
                  collection, use and storage;
                </p>

                <p>
                  2.5.2. fair processing and all other appropriate notices have
                  been provided to the Data Subjects of the Protected Data (and
                  all necessary consents from such Data Subjects obtained and at
                  all times maintained) to the extent required by Data
                  Protection Laws in connection with all processing activities
                  in respect of the Protected Data which may be undertaken by us
                  and our Sub-Processors in accordance with our Agreement;
                </p>

                <p>2.5.3. the Protected Data is accurate and up to date;</p>

                <p>
                  2.5.4. except to the extent resulting from Transfers to
                  International Recipients made by us or any Sub-Processor, the
                  Protected Data is not subject to the laws of any jurisdiction
                  outside of the United Kingdom;
                </p>

                <p>
                  2.5.5. you will establish and maintain adequate security
                  measures to safeguard the Protected Data in your possession or
                  control (including from unauthorised or unlawful destruction,
                  corruption, processing or disclosure) and maintain complete
                  and accurate backups of all Protected Data provided to us (or
                  anyone acting on its behalf) so as to be able to immediately
                  recover and reconstitute such Protected Data in the event of
                  loss, damage or corruption of such Protected Data by us or any
                  other person;
                </p>

                <p>
                  2.5.6. all instructions given by you to us in respect of
                  Personal Data will at all times be in accordance with Data
                  Protection Laws; and
                </p>

                <p>
                  2.5.7. you have undertaken due diligence in relation to our
                  processing operations and commitments and are satisfied (and
                  all times you continue to receive the benefit of any Services
                  and/or use the PPC Package remain satisfied) that:
                </p>

                <p>
                  2.5.7.1. our processing operations are suitable for the
                  purposes for which you propose to receive the benefit of any
                  Services and use the PPC Package and engage us to process the
                  Protected Data;
                </p>

                <p>
                  2.5.7.2. your technical and organisational measures ensure a
                  level of security appropriate to the risk in regards to the
                  Protected Data as required by Data Protection Laws; and
                </p>

                <p>
                  2.5.7.3. we have sufficient expertise, reliability and
                  resources to implement technical and organisational measures
                  that meet the requirements of Data Protection Laws.
                </p>

                <Heading className="mt-xl" level={3}>
                  3. INSTRUCTIONS AND DETAILS OF PROCESSING
                </Heading>

                <p>
                  3.1. Insofar as we process Protected Data on your behalf, we:
                </p>

                <p>
                  3.1.1. unless required to do otherwise by Applicable Law, will
                  (and will take steps to ensure each person acting under its
                  authority will) process the Protected Data only on and in
                  accordance with your documented instructions (including with
                  regard to Transfers of Protected Data to any International
                  Recipient), as updated with both of our agreement from time to
                  time ( <strong>Processing Instructions</strong> );
                </p>

                <p>
                  3.1.2. if Applicable Law requires us to process Protected Data
                  other than in accordance with the Processing Instructions,
                  will notify you of any such requirement before processing the
                  Protected Data (unless Applicable Law prohibits such
                  information on important grounds of public interest); and
                </p>

                <p>
                  3.1.3. will promptly inform you if we become aware of a
                  Processing Instruction that, in our opinion, infringes Data
                  Protection Laws, provided that:
                </p>

                <p>
                  3.1.3.1 this will be without prejudice to paragraphs 2.4 and
                  2.5; and
                </p>

                <p>
                  3.1.3.2 to the maximum extent permitted by Applicable Law, we
                  will have no liability howsoever arising (whether in contract,
                  tort (including negligence) or otherwise) for any losses,
                  costs, expenses or liabilities (including any Data Protection
                  Losses) arising from or in connection with any processing in
                  accordance with the Processing Instructions following your
                  receipt of the information required by this paragraph 3.1.
                </p>

                <p>
                  3.2. You acknowledge and agree that the execution of any
                  computer command to process (including deletion of) any
                  Protected Data made in the use of any of the PPC Package by an
                  Authorised User will be a Processing Instruction (other than
                  to the extent such command is not fulfilled due to technical,
                  operational or other reasons). You will ensure that Authorised
                  Users do not execute any such command unless authorised by you
                  (and by all other relevant Controller(s)) and acknowledge and
                  accept that if any Protected Data is deleted pursuant to any
                  such command we are under no obligation to seek to restore it.
                </p>

                <p>
                  3.3. The processing of the Protected Data by us under our
                  Agreement will involve the subject matter (set out in this
                  Agreement). The duration of the processing shall be the Term.
                  The purpose of processing shall be the purposes as set out in
                  the Agreement (including fulfilling our obligations under the
                  Agreement and for the legitimate reasons of preventing click
                  fraud and other potential fraudulent actions) and the nature
                  of the processing shall be the collecting, recording,
                  processing and storing of data. The type of personal data
                  includes unique identifiers, namely IP addresses and
                  geo-location data, and employee information, and the
                  categories of the data subject include the customer’s
                  authorised users. The data controller’s obligations and rights
                  shall be as set out in this DPA.
                </p>

                <Heading className="mt-xl" level={3}>
                  4. TECHNICAL AND ORGANISATIONAL MEASURES
                </Heading>

                <p>
                  4.1. We will implement and maintain technical and
                  organisational measures:
                </p>

                <p>
                  4.1.1. in relation to the processing of Protected Data by us,
                  as set out the Information Security Policy; and
                </p>

                <p>
                  4.1.2. to assist you insofar as is possible (taking into
                  account the nature of the processing) in the fulfilment of
                  your obligations to respond to Data Subject Requests relating
                  to Protected Data, in each case at your cost on a time and
                  materials basis in accordance with our standard rates. We each
                  have agreed that (taking into account the nature of the
                  processing) our compliance with paragraph 6.1 will constitute
                  our sole obligations under this paragraph 4.1.2.
                </p>

                <Heading className="mt-xl" level={3}>
                  5. USING STAFF AND OTHER PROCESSORS
                </Heading>

                <p>
                  5.1. We have your general authorisation for the engagement
                  (whether directly or via a Sub-Processor) of any
                  Sub-Processors from time to time. We will issue a notice to
                  you at least 5 Business Days prior to any intended changes
                  concerning the addition or replacement of a Sub-Processor
                  (whether by us or any Sub-Processor) (the{" "}
                  <strong>Objection Period</strong>
                  ). You will not unreasonably withhold, condition, delay or
                  object to the appointment of any Sub-Processor. Provided we
                  have complied with our obligations under this DPA we will be
                  permitted to engage such new or replacement Sub-Processor
                  following the end of the Objection Period if you do not object
                  prior to the end of the Objection Period.
                </p>

                <p>5.2. We will:</p>

                <p>
                  5.2.1. prior to the relevant Sub-Processor carrying out any
                  processing activities in respect of the Protected Data, ensure
                  each Sub-Processor is appointed under a written contract
                  containing materially the same obligations as under paragraphs
                  2 to 12 (inclusive) (including those obligations relating to
                  sufficient guarantees to implement appropriate technical and
                  organisational measures);
                </p>

                <p>
                  5.2.2. ensure each such Sub-Processor complies with all such
                  obligations; and
                </p>

                <p>
                  5.2.3. remain fully liable for all the acts and omissions of
                  each Sub-Processor as if they were our own.
                </p>

                <p>
                  5.3. We will ensure that all natural persons authorised by us
                  (or by any Sub-Processor) to process Protected Data are
                  subject to a binding written contractual obligation to keep
                  the Protected Data confidential (except where disclosure is
                  required in accordance with Applicable Law, in which case we
                  will, where practicable and not prohibited by Applicable Law,
                  notify you of any such requirement before such disclosure).
                </p>

                <Heading className="mt-xl" level={3}>
                  6. ASSISTANCE WITH COMPLIANCE AND DATA SUBJECT RIGHTS
                </Heading>

                <p>
                  6.1. We will refer all Data Subject Requests we receive to you
                  without undue delay. You will pay us for all work, time, costs
                  and expenses incurred by us or any Sub-Processor(s) in
                  connection with such activity, calculated on a time and
                  materials basis at our then current rates.
                </p>

                <p>
                  6.2. We will provide such assistance as you reasonably require
                  (taking into account the nature of processing and the
                  information available to us) to you in ensuring compliance
                  with your obligations under Data Protection Laws with respect
                  to:
                </p>

                <p>6.2.1. security of processing;</p>

                <p>
                  6.2.2 data protection impact assessments (as such term is
                  defined in Data Protection Laws);
                </p>

                <p>
                  6.2.3 prior consultation with a Supervisory Authority
                  regarding high risk processing; and
                </p>

                <p>
                  6.2.4 notifications to the Supervisory Authority and/or
                  communications to Data Subjects by you in response to any
                  Personal Data Breach,
                </p>
                <p>
                  provided you will pay us for all work, time, costs and
                  expenses incurred us or any Sub-Processor(s) in connection
                  with providing the assistance in this paragraph 6.2,
                  calculated on a time and materials basis at our then current
                  rates.
                </p>

                <Heading className="mt-xl" level={3}>
                  7. INTERNATIONAL DATA TRANSFERS
                </Heading>

                <p>
                  7.1. Subject to paragraphs 7.2 and 7.3, we will not Transfer
                  any Protected Data:
                </p>

                <p>7.1.1. from any country to any other country; and/or</p>

                <p>
                  7.1.2 to an organisation and/or its subordinate bodies
                  governed by public international law, or any other body which
                  is set up by, or on the basis of, an agreement between two or
                  more countries,
                </p>

                <p>
                  without your prior written authorisation except where required
                  by Applicable Law (in which case the provisions of paragraph
                  3.1 will apply).
                </p>

                <p>
                  7.2. You hereby authorise us (or any Sub-Processor) to
                  Transfer any Protected Data for the purposes referred to in
                  paragraph 3.3 to any International Recipient(s) in accordance
                  with paragraph 7.1, provided all Transfers of Protected Data
                  by us of Protected Data to an International Recipient will (to
                  the extent required under Data Protection Laws) be effected by
                  way of Lawful Safeguards and in accordance with Data
                  Protection Laws and our Agreement. The provisions of our
                  Agreement (including this DPA) will constitute your
                  instructions with respect to Transfers in accordance with
                  paragraph 3.1.
                </p>

                <p>
                  7.3. You acknowledge that due to the nature of cloud services,
                  the Protected Data may be Transferred to other geographical
                  locations in connection with use of the PPC Package further to
                  access and/or computerised instructions initiated by
                  Authorised Users. You acknowledge that we do not control such
                  processing and you will ensure that Authorised Users (and all
                  others acting on its behalf) only initiate the Transfer of
                  Protected Data to other geographical locations if Lawful
                  Safeguards are in place and that such Transfer is in
                  compliance with all Applicable Laws.
                </p>

                <Heading className="mt-xl" level={3}>
                  8. INFORMATION AND AUDIT
                </Heading>

                <p>
                  8.1. We will maintain, in accordance with Data Protection Laws
                  binding on us, written records of all categories of processing
                  activities carried out on your behalf.
                </p>

                <p>
                  8.2. On request, we will provide you (or auditors mandated by
                  you) with a copy of the third party certifications and audits
                  to the extent made generally available to our customers. Such
                  information will be confidential to us and will be our
                  Proprietary Information as defined in our Agreement, and will
                  be treated in accordance with applicable terms.
                </p>

                <p>
                  8.3. In the event that you, acting reasonably, deem the
                  information provided in accordance with paragraph 8.2
                  insufficient to satisfy your obligations under Data Protection
                  Laws, we will, on request by you make available to you such
                  information as is reasonably necessary to demonstrate our
                  compliance with our obligations under this DPA and Article 28
                  of the GDPR, and allow for and contribute to audits, including
                  inspections, by you or another auditor mandated by you) for
                  this purpose provided:
                </p>

                <p>
                  8.3.1. such audit, inspection or information request is
                  reasonable, limited to information in our possession or
                  control and is subject to you giving us reasonable (and in any
                  event at least 60 days’) prior notice of such audit,
                  inspection or information request;
                </p>

                <p>
                  8.3.2. we each (each acting reasonably and consent not to be
                  unreasonably withheld or delayed) will agree the timing, scope
                  and duration of the audit, inspection or information release
                  together with any specific policies or other steps with which
                  you or a third party auditor will comply (including to protect
                  the security and confidentiality of other customers, to ensure
                  we are not placed in breach of any other arrangement with any
                  other customer and so as to comply with the remainder of this
                  paragraph 8.3);
                </p>

                <p>
                  8.3.3. you will ensure that any such audit or inspection is
                  undertaken during normal business hours, with minimal
                  disruption to our businesses;
                </p>

                <p>
                  8.3.4. the duration of any audit or inspection will be limited
                  to one Business Day;
                </p>

                <p>
                  8.3.5. all costs of such audit or inspection or responding to
                  such information request will be borne by you, and our costs,
                  expenses, work and time incurred in connection with such audit
                  or inspection will be reimbursed by you on a time and
                  materials basis in accordance with our then current rates;
                </p>

                <p>
                  8.3.6. your rights under this paragraph 8.3 may only be
                  exercised once in any consecutive 12 month period, unless
                  otherwise required by a Supervisory Authority or if you
                  (acting reasonably) believe we are in breach of this DPA;
                </p>

                <p>
                  8.3.7. you will promptly (and in any event within one Business
                  Day) report any non-compliance identified by the audit,
                  inspection or release of information to us;
                </p>

                <p>
                  8.3.8. you agree that all information obtained or generated by
                  you or your auditor(s) in connection with such information
                  requests, inspections and audits will be our Proprietary
                  Information as defined in our Agreement, and will be treated
                  in accordance with applicable terms;
                </p>

                <p>
                  8.3.9. you will ensure that each person acting on your behalf
                  in connection with such audit or inspection (including the
                  personnel of any third party auditor) will not by any act or
                  omission cause or contribute to any damage, destruction, loss
                  or corruption of or to any systems, equipment or data in our
                  control or possession while conducting any such audit or
                  inspection; and
                </p>

                <p>8.3.10. this paragraph 8.3 is subject to paragraph 8.4.</p>

                <p>
                  8.4. You acknowledge and accept that relevant contractual
                  terms agreed with Sub-Processor(s) may mean that we or you may
                  not be able to undertake or facilitate an information request
                  or audit or inspection of any or all Sub-Processors pursuant
                  to paragraph 8.3 and:
                </p>

                <p>
                  8.4.1. your rights under paragraph 8.3 will not apply to the
                  extent inconsistent with relevant contractual terms agreed
                  with Sub-Processor(s);
                </p>

                <p>
                  8.4.2. to the extent any information request, audit or
                  inspection of any Sub-Processor are permitted in accordance
                  with this paragraph 8.4, equivalent restrictions and
                  obligations on you to those in paragraphs 8.3.1 to 8.3.10
                  (inclusive) will apply together with any additional or more
                  extensive restrictions and obligations applicable in the
                  circumstances; and
                </p>

                <p>
                  8.4.3. paragraphs 8.2 and 8.3 will be construed accordingly.
                </p>

                <Heading className="mt-xl" level={3}>
                  9. BREACH NOTIFICATION
                </Heading>

                <p>
                  9.1. In respect of any Personal Data Breach, we will, without
                  undue delay (and in any event within 72 hours):
                </p>

                <p>9.1.1. notify you of the Personal Data Breach; and</p>

                <p>
                  9.1.2. provide you with details of the Personal Data Breach.
                </p>

                <Heading className="mt-xl" level={3}>
                  10. DELETION OF PROTECTED DATA AND COPIES
                </Heading>

                <p>
                  Following the end of the provision of the Services and the PPC
                  Package (or any part) relating to the processing of Protected
                  Data we will dispose of Protected Data in accordance with our
                  obligations under our Agreement. We will have no liability
                  (howsoever arising, including in negligence) for any deletion
                  or destruction of any such Protected Data undertaken in
                  accordance with our Agreement.
                </p>

                <Heading className="mt-xl" level={3}>
                  11. COMPENSATION AND CLAIMS
                </Heading>

                <p>
                  11.1. You will indemnify and keep us indemnified in respect of
                  all Data Protection Losses suffered or incurred by, awarded
                  against or agreed to be paid by, us and any Sub-Processor
                  arising from or in connection with any: non-compliance by you
                  with the Data Protection Laws; processing carried out by us or
                  any Sub-Processor pursuant to any Processing Instruction that
                  infringes any Data Protection Law; or breach by you of any of
                  your obligations under our Agreement, except to the extent
                  that we are liable under paragraph 11.2
                </p>

                <p>
                  11.2. Our liability under this DPA shall be as set out in the
                  Conditions.
                </p>

                <p>
                  11.3. If one of us receives a compensation claim from a person
                  relating to processing of Protected Data in connection with
                  our Agreement, the Services or the PPC Package, it will
                  promptly provide the other one with notice and full details of
                  such claim.
                </p>

                <p>
                  11.4. We each agree that you will not be entitled to claim
                  back from us any part of any compensation paid by you in
                  respect of such damage to the extent that you are liable to
                  indemnify or otherwise compensate us in accordance with our
                  Agreement.
                </p>

                <p>
                  11.5. This paragraph 11 is intended to apply to the allocation
                  of liability for Data Protection Losses as between us,
                  including with respect to compensation to Data Subjects,
                  notwithstanding any provisions under Data Protection Laws to
                  the contrary, except:
                </p>

                <p>
                  11.5.1. to the extent not permitted by Applicable Law
                  (including Data Protection Laws); and
                </p>

                <p>
                  11.5.2. that it does not affect the liability of either party
                  to any Data Subject.
                </p>

                <Heading className="mt-xl" level={3}>
                  12. SURVIVAL
                </Heading>

                <p>
                  This DPA will survive termination (for any reason) or expiry
                  of our Agreement and continue until no Protected Data remains
                  in our or any Sub-Processor’s possession or control, except
                  that paragraphs 10 to 12 (inclusive) will continue
                  indefinitely.
                </p>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export default PrivacyPage
